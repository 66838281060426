<template>
  <b-sidebar
    id="option-sidebar"
    sidebar-class="option-sidebar"
    right
    shadow
  >
    <template #header>
      <div class="title">
        Options
      </div>
      <feather-icon
        v-b-toggle.option-sidebar
        class="cursor-pointer"
        icon="XIcon"
        size="24"
      />
    </template>
    <div class="columns-form">
      <div class="label">
        Columns
      </div>
      <template v-for="field in modelValue.fields">
        <HeroCheckbox
          v-if="field.key !== 'action'"
          :id="field.key"
          :key="field.key"
          v-model="field.isActive"
          :label="field.label"
          :disabled="isCheckboxDisabled(field)"
          @input="onInput"
        />
      </template>
    </div>
    <hr>
    <HeroButtonAction
      variant="primary"
      type="button"
      class="btn-copy"
      :disabled="!items.length"
      @click="handleCopyUnmappedLeadIds"
    >
      <feather-icon icon="CopyIcon" size="24" />
      {{ isCopied ? 'Copied' : 'Copy' }} Unmapped Lead IDs
    </HeroButtonAction>
  </b-sidebar>
</template>

<script>
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'

export default {
  name: 'OptionSidebar',
  components: { HeroButtonAction, HeroCheckbox },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isCopied: false,
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    const options = JSON.parse(localStorage.getItem('ul-options-columns'))
    if (options) {
      this.modelValue.fields = this.modelValue.fields.map(field => ({
        ...field,
        isActive: options.find(option => option.key === field.key)?.isActive ?? field.isActive,
      }))
    }
  },
  methods: {
    onInput() {
      localStorage.setItem('ul-options-columns', JSON.stringify(this.modelValue.fields))
    },

    isCheckboxDisabled(currentField) {
      return currentField.isActive && this.modelValue.fields.filter(field => field.isActive && field.key !== 'action').length === 1
    },

    handleCopyUnmappedLeadIds() {
      this.isCopied = true
      const unmappedLeadIds = this.items.filter(item => !item.campaign_id && !item.channel_id).map(item => item.id)
      navigator.clipboard.writeText(unmappedLeadIds.join())
      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
.option-sidebar {
  height: 100%;
  background-color: white !important;
  .b-sidebar-header {
    justify-content: space-between;
    .title {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  .b-sidebar-body {
    padding: 6px 16px 16px 16px;
    .columns-form {
      .label {
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 14px;
      }
    }
    .btn-copy {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }
  }
}
</style>
