<template>
  <b-modal
    id="batch-exclude-leads-modal"
    ref="batch-exclude-leads-modal"
    title="Batch Exclude Leads"
    centered
    hide-footer
    :hide-header-close="isLoading"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <b-overlay no-wrap :show="isLoading" />
    <b-form @submit.prevent="handleSubmit">
      <HeroVueSelect
        id="reason"
        v-model="reason"
        v-b-tooltip.top="!excludeItems.length ? 'Please select the leads you want to exclude' : ''"
        :options="reasonOptions"
        :disabled="isLoading || !excludeItems.length"
        class="mb-0 custom-reason-select"
      />
      <hr>
      <b-row class="justify-content-between">
        <b-col cols="6" sm="3">
          <HeroButtonAction
            type="button"
            variant="outline-secondary"
            @click="$bvModal.hide('batch-exclude-leads-modal')"
          >
            Close
          </HeroButtonAction>
        </b-col>
        <b-col cols="6" offset-sm="6" sm="3">
          <HeroButtonAction
            type="submit"
            variant="primary"
            :disabled="!excludeItems.length || !reason"
          >
            Save
          </HeroButtonAction>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>
<script>

import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import axiosInstance from '@/libs/axiosInstance'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'

export default {
  components: {
    HeroVueSelect,
    HeroButtonAction,
  },
  props: {
    excludeItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      reason: '',
      reasonOptions: [
        {
          text: 'None',
          value: '',
        },
        {
          text: 'Client has already churned but is still using Heroleads\' ad forms.',
          value: 'Client has already churned but is still using Heroleads\' ad forms.',
        },
        {
          text: 'Client don\'t use HeroVisionX or don\'t have notification.',
          value: 'Client don\'t use HeroVisionX or don\'t have notification.',
        },
        {
          text: 'Another agency / client using Heroleads\' ad forms.',
          value: 'Another agency / client using Heroleads\' ad forms.',
        },
        {
          text: 'Test lead.',
          value: 'Test lead.',
        },
      ],
    }
  },
  methods: {
    async handleSubmit() {
      if (!this.excludeItems.length || !this.reason) return

      try {
        this.isLoading = true

        const leadIds = this.excludeItems.map(excludeItem => excludeItem.leadId)

        const baseUrl = `${process.env.VUE_APP_LEAD_SERVICE_API}`
        await axiosInstance.post(`${baseUrl}/lead/excluded-reason/batch-assign`, {
          leadIds,
          reason: this.reason,
        }, { withCredentials: false })

        this.$emit('update-success')
        this.$bvModal.hide('batch-exclude-leads-modal')
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.isLoading = false
      }
    },

    onHidden() {
      this.reason = ''
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .modal-backdrop {
  width: 100%;
  height: 100%;
}

::v-deep .modal-dialog {
  @media(min-width: 576px) {
    max-width: fit-content;
  }
}

.custom-reason-select {
  @media(min-width: 576px) {
    width: 540px;
  }
}
</style>
